import React from "react"

import { AdminLayout } from "components/layout-custom"
import Vehicles from "components/vehicles-old"

const Page = () => (
  <AdminLayout title="Live Vehicle Data" padded={false}>
    <Vehicles type="admin" />
  </AdminLayout>
)

export default Page
